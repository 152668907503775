import "../styles/styles.css"

import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import {
  initializeFirestore,
  doc,
  collection,
  addDoc,
  setDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  query,
  where,
  writeBatch,
  persistentLocalCache,
  persistentMultipleTabManager,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore"

import melodyPhoto from "../images/melody.jpg"
import simonPhoto from "../images/simon.jpg"
import eleanorPhoto from "../images/eleanor.jpg"
import mamanPhoto from "../images/maman.jpg"
import papaPhoto from "../images/papa.jpg"

new (class App {
  profiles = [
    { name: "Melody", email: "melodyleclercq@icloud.com", roles: ["child"], photo: melodyPhoto },
    { name: "Simon", email: "simonleclercq@icloud.com", roles: ["child"], photo: simonPhoto },
    { name: "Eleanor", email: "eleanorleclercq@icloud.com", roles: ["child"], photo: eleanorPhoto },
    { name: "Maman", email: "yesflav@me.com", roles: ["parent"], photo: mamanPhoto },
    { name: "Papa", email: "maiwenn@me.com", roles: ["parent", "admin"], photo: papaPhoto },
  ]

  firebaseConfig = {
    apiKey: "AIzaSyBwpudEjCNmyJuEoxDYXGVvT-4jnuj36AM",
    authDomain: "family-cagnotte.firebaseapp.com",
    projectId: "family-cagnotte",
    storageBucket: "family-cagnotte.appspot.com",
    messagingSenderId: "410441703947",
    appId: "1:410441703947:web:1738b7838e5cfec3cfdd0e",
  }

  selectedPeriod = "d"
  countersResetInProgress = false

  constructor() {
    if (process.env.NODE_ENV === "production") this.registerSw()
    this.app = initializeApp(this.firebaseConfig)
    this.auth = getAuth(this.app)
    this.db = initializeFirestore(this.app, {
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
      }),
    })
    this.listen()
  }

  showApp() {
    document.getElementById("login-container").style.display = "none"
    document.getElementById("app").style.display = "flex"
  }

  showLogin() {
    document.getElementById("login-container").style.display = "flex"
    document.getElementById("app").style.display = "none"
  }

  showAdminOptions() {
    const resetEl = document.getElementById("reset")
    resetEl.style.display = "block"
    resetEl.addEventListener("click", this.resetByPeriod.bind(this))
  }

  listen() {
    document.addEventListener("visibilityChange", this.handleVisibilityChange.bind(this), false)
    document.getElementById("login-button").addEventListener("click", this.login.bind(this))
    document.getElementById("logout-button").addEventListener("click", this.logout.bind(this))
    document.getElementById("caches-refresh").addEventListener("click", this.forceCachesRefresh.bind(this))
    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user
        this.initApp()
      } else {
        this.showLogin()
      }
    })
    document.querySelectorAll(".period-buttons button").forEach(button => {
      button.addEventListener("click", () => {
        this.selectedPeriod = button.id
        document.querySelectorAll(".period-buttons button").forEach(btn => btn.classList.remove("active"))
        button.classList.add("active")
        this.updateViewWithData()
        this.scrollTop()
      })
    })
    this.listenPullToRefresh()
  }

  listenActions() {
    for (const profile of this.profiles) {
      const incrementsRef = collection(this.db, "users", profile.email, "increments")
      const decrementsRef = collection(this.db, "users", profile.email, "decrements")
      const bonusRef = collection(this.db, "bonus")

      let initialLoadIncrements = true
      let initialLoadDecrements = true
      let initialLoadBonus = true

      onSnapshot(incrementsRef, snapshot => {
        const docChanges = snapshot.docChanges()
        if (initialLoadIncrements) {
          initialLoadIncrements = false
          return
        }
        let changeDetected = false
        docChanges.forEach(change => {
          if (change.type === "added" || change.type === "removed") {
            changeDetected = true
          }
        })
        if (changeDetected) this.debouncedUpdateViewWithData()
      })

      onSnapshot(decrementsRef, snapshot => {
        const docChanges = snapshot.docChanges()
        if (initialLoadDecrements) {
          initialLoadDecrements = false
          return
        }
        let changeDetected = false
        docChanges.forEach(change => {
          if (change.type === "added" || change.type === "removed") {
            changeDetected = true
          }
        })
        if (changeDetected) this.debouncedUpdateViewWithData()
      })

      onSnapshot(bonusRef, snapshot => {
        const docChanges = snapshot.docChanges()
        if (initialLoadBonus) {
          initialLoadBonus = false
          return
        }
        docChanges.forEach(async change => {
          if (change.type === "added" || change.type === "removed") {
            await this.getBonusData()
            this.updateBonusView()
          }
        })
      })
    }
  }

  async login() {
    try {
      const email = document.getElementById("email").value
      const password = document.getElementById("password").value
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
      this.currentUser = userCredential.user
      this.currentUser.roles = this.profiles.find(profile => profile.email === this.currentUser.email)?.roles
      this.initApp()
    } catch (error) {
      alert("Login failed: " + error.message)
    }
  }

  async logout() {
    try {
      await this.auth.signOut()
    } catch (error) {
      this.showLogin()
    }
  }

  buildView() {
    this.buildProfiles()
    if (this.isAdmin()) {
      this.showAdminOptions()
    }
  }

  async initApp() {
    this.buildView()
    this.registerDailyBonus()
    await this.updateViewWithData()
    this.scrollTop()
    this.listenActions()
  }

  buildProfiles() {
    const profilesContainer = document.getElementById("profiles")
    profilesContainer.innerHTML = ""
    this.profiles.forEach(profile => {
      profilesContainer.innerHTML += `
                            <div class="profile" id="profile-${profile.email}">
                                <div class="button-container">
                                    <button class="action decrement">-</button>
                                    <div id="profile-${profile.email}-dec-count"></div>
                                </div>
                                <div class="profile-details">
                                    <img src=${profile.photo} loading="lazy" alt="${profile.name}">
                                    <div class="name">${profile.name}</div>
                                    <div class="counter" id="profile-${profile.email}-counter"></div>
                                </div>
                                <div class="button-container">
                                    <button class="action increment">+</button>
                                    <div id="profile-${profile.email}-inc-count"></div>
                                </div>
                            </div>`
    })
    document.querySelectorAll(".action").forEach(button => {
      button.addEventListener("click", () => {
        const action = button.classList.contains("increment") ? "increment" : "decrement"
        const profileElement = button.closest(".profile")
        const email = profileElement.id.replace("profile-", "")
        this.makeCounterAction(email, action)
      })
    })
  }

  async makeCounterAction(email, action) {
    if (!this.isParent()) {
      alert("hé ho! Seulement les parents changent les compteurs !")
      return
    }
    try {
      const promises = []
      const ref = collection(this.db, "users", email, action === "increment" ? "increments" : "decrements")
      promises.push(
        addDoc(ref, {
          ts: serverTimestamp(),
        })
      )
      if (action === "decrement") {
        this.cancelDailyBonus()
      }
      await Promise.all(promises)
    } catch (error) {
      console.error("Error making counter action: ", error)
    }
  }

  getPeriodStartTimestamp() {
    const now = new Date()
    let start
    switch (this.selectedPeriod) {
      case "d":
        start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        break
      case "w":
        const day = now.getDay()
        const diff = now.getDate() - day + (day === 0 ? -6 : 1)
        start = new Date(now.setDate(diff))
        start.setHours(0, 0, 0, 0)
        break
      case "m":
        start = new Date(now.getFullYear(), now.getMonth(), 1)
        break
      case "a":
        start = new Date(1970, 0, 1)
        break
      default:
        start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    }
    return Timestamp.fromDate(start)
  }

  getCagnotteLabel() {
    let label = ""
    switch (this.selectedPeriod) {
      case "d":
        label = "du jour"
        break
      case "w":
        label = "de la semaine"
        break
      case "m":
        label = "du mois"
        break
      case "a":
        label = "totale"
        break
      default:
        label = ""
    }
    return label
  }

  getNumberClassName(num) {
    if (num > 0) {
      return "positive"
    } else if (num < 0) {
      return "negative"
    }
  }

  async getCountersData() {
    try {
      const periodStartTimestamp = this.getPeriodStartTimestamp()
      for (const profile of this.profiles) {
        const incQ = query(collection(this.db, "users", profile.email, "increments"), where("ts", ">=", periodStartTimestamp))
        const decQ = query(collection(this.db, "users", profile.email, "decrements"), where("ts", ">=", periodStartTimestamp))
        const [incSnapshot, decSnapshot] = await Promise.all([incQ, decQ].map(i => getDocs(i)))
        profile.increments = incSnapshot.size
        profile.decrements = decSnapshot.size
      }
    } catch (error) {
      console.error("Error getting counters data: ", error)
    }
  }
  async getBonusData() {
    try {
      const periodStartTimestamp = this.getPeriodStartTimestamp()
      const q = query(collection(this.db, "bonus"), where("date", ">", periodStartTimestamp.toDate().toISOString().split("T")[0]), where("cancelled", "==", false))
      const snapshot = await getDocs(q)
      this.bonus = snapshot?.docs?.reduce((total, doc) => total + (doc.data().value || 0), 0) ?? 0
    } catch (error) {
      console.error("Error getting bonus data: ", error)
    }
  }
  updateCountersView() {
    for (const profile of this.profiles) {
      const incEl = document.getElementById(`profile-${profile.email}-inc-count`)
      const decEl = document.getElementById(`profile-${profile.email}-dec-count`)
      const incActionBtn = incEl.previousElementSibling
      const decActionBtn = decEl.previousElementSibling
      incActionBtn.disabled = this.selectedPeriod != "d"
      decActionBtn.disabled = this.selectedPeriod != "d"
      const countEl = document.getElementById(`profile-${profile.email}-counter`)
      const profileCount = profile.increments - profile.decrements
      if (incEl) incEl.textContent = profile.increments
      if (decEl) decEl.textContent = profile.decrements
      countEl.classList.remove("positive")
      countEl.classList.remove("negative")
      if (profileCount !== 0) {
        countEl.classList.add(this.getNumberClassName(profileCount))
      }
      countEl.textContent = profileCount
    }
  }
  updateCagnotteView() {
    this.cagnotte = 0
    for (const profile of this.profiles) {
      const profileCount = profile.increments - profile.decrements
      this.cagnotte += profileCount
    }
    const cagnotteEl = document.getElementById("cagnotte")
    cagnotteEl.classList.remove("positive")
    cagnotteEl.classList.remove("negative")
    cagnotteEl.classList.add(this.getNumberClassName(this.cagnotte))
    if (cagnotteEl) cagnotteEl.textContent = `${this.cagnotte}€`
    const cagnotteLabelEl = document.getElementById("cagnotte-period")
    if (cagnotteLabelEl) cagnotteLabelEl.textContent = this.getCagnotteLabel()

    const total = this.cagnotte + this.bonus
    const totalEl = document.getElementById("total")
    if (totalEl) totalEl.textContent = this.bonus > 0 ? ` = ${total} €` : ""
  }
  updateBonusView() {
    document.getElementById("bonus").innerHTML = this.bonus > 0 ? `&nbsp;+${this.bonus}&nbsp;` : ""
  }

  async registerDailyBonus() {
    const today = new Date().toISOString().split("T")[0]
    const q = query(collection(this.db, "bonus"), where("date", "==", today), where("type", "==", "daily"))
    const docs = await getDocs(q)
    if (!docs.size) {
      setDoc(doc(this.db, "bonus", `${today}-${this.randomStr(5)}`), {
        date: today,
        type: "daily",
        value: 10,
        cancelled: false,
      })
    }
  }

  async cancelDailyBonus() {
    const today = new Date().toISOString().split("T")[0]
    const q = query(collection(this.db, "bonus"), where("date", "==", today), where("type", "==", "daily"))
    const docs = await getDocs(q)
    if (docs.size) {
      docs.forEach(doc => {
        updateDoc(doc.ref, {
          cancelled: true,
        })
      })
    }
  }

  randomStr(length) {
    return Array.from({ length }, () => Math.random().toString(36).charAt(2)).join("")
  }

  sortProfiles() {
    const profilesContainer = document.getElementById("profiles")
    const profiles = Array.from(profilesContainer.getElementsByClassName("profile"))
    profiles.sort((a, b) => {
      const aCounter = parseFloat(a.querySelector(".counter").textContent.replace("€", ""))
      const bCounter = parseFloat(b.querySelector(".counter").textContent.replace("€", ""))
      return bCounter - aCounter
    })
    // setTimeout(() => {
    profiles.forEach(profile => {
      profilesContainer.appendChild(profile)
    })
    // }, 500)
  }

  isAdmin() {
    return this.profiles.find(profile => profile.email === this.currentUser.email)?.roles.includes("admin")
  }
  isParent() {
    return this.profiles.find(profile => profile.email === this.currentUser.email)?.roles.includes("parent")
  }
  isChild() {
    return this.profiles.find(profile => profile.email === this.currentUser.email)?.roles.includes("child")
  }

  handleVisibilityChange() {
    if (!document.hidden) {
      this.updateViewWithData()
    }
  }

  scrollTop() {
    document.querySelector('#app').scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async updateViewWithData() {
    await Promise.all([this.getCountersData(), this.getBonusData()])
    this.updateCountersView()
    this.updateCagnotteView()
    this.updateBonusView()
    this.sortProfiles()
    this.showApp()
  }

  debounce(func, wait) {
    let timeout
    return function(...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  debouncedUpdateViewWithData = this.debounce(() => {
    if (!this.countersResetInProgress) this.updateViewWithData()
  }, 1000)

  async resetByPeriod() {
    const confirmation = confirm(`Are you sure you want to reset all counters for the selected period ?`)
    if (!confirmation) return
    try {
      this.countersResetInProgress = true
      const users = this.profiles.map(p => p.email)
      const periodStart = this.getPeriodStartTimestamp()
      for (const userEmail of users) {
        const userIncrementsRef = collection(this.db, "users", userEmail, "increments")
        const userDecrementsRef = collection(this.db, "users", userEmail, "decrements")
        await Promise.all([
          this.deleteCollectionByPeriod(userIncrementsRef, periodStart),
          this.deleteCollectionByPeriod(userDecrementsRef, periodStart)
        ])
      }
      this.countersResetInProgress = false
      this.updateViewWithData()
    } catch (error) {
      console.error("Error resetting counters: ", error)
    }
  }

  async deleteCollectionByPeriod(collectionRef, periodStart) {
    const q = query(collectionRef, where("ts", ">=", periodStart))
    const snapshot = await getDocs(q)
    const batch = writeBatch(this.db)
    snapshot.docs.forEach(doc => {
      batch.delete(doc.ref)
    })
    await batch.commit()
  }

  async forceCachesRefresh() {
    await Promise.all([this.unregisterSw(), this.clearCaches()])
    location.reload()
  }

  async registerSw() {
    if ("serviceWorker" in navigator) {
      try {
        window.addEventListener("load", async () => {
          const registration = await navigator.serviceWorker.register("/service-worker.js")
          // console.log("Service Worker registered with scope:", registration.scope)
        })
      } catch (error) {
        console.error("Service Worker registration error:", error)
      }
    }
  }

  async unregisterSw() {
    if ("serviceWorker" in navigator) {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations()
        for (const registration of registrations) {
          await registration.unregister()
          // console.log("Service Worker unregistered successfully.")
        }
      } catch (error) {
        console.error("Service Worker unregistration error:", error)
      }
    }
  }

  async clearCaches() {
    if ("caches" in window) {
      try {
        const cacheNames = await caches.keys()
        for (const cacheName of cacheNames) {
          await caches.delete(cacheName)
          console.log(`Cache ${cacheName} deleted successfully.`)
        }
      } catch (error) {
        console.error(`Cache ${cacheName} deletion error:`, error)
      }
    }
  }

  listenPullToRefresh() {
    const content = document.querySelector("#app")
    const refreshIndicator = document.querySelector(".refresh-indicator")
    let startY = 0
    let isPulling = false

    const onTouchStart = e => {
      if (content.scrollTop === 0) {
        startY = e.touches[0].pageY
        isPulling = true
        content.style.transition = "none"
      }
    }

    const onTouchMove = e => {
      if (isPulling) {
        const moveY = e.touches[0].pageY
        const pullDistance = moveY - startY
        if (pullDistance > 0) {
          e.preventDefault()
          const pullResistance = Math.min(pullDistance / 2, 100)
          content.style.transform = `translateY(${pullResistance}px)`
          refreshIndicator.style.transform = `translateY(${Math.min(pullResistance, 50)}px)`

          if (pullDistance > 0) {
            refreshIndicator.classList.add("visible")
          }
        }
      }
    }

    const onTouchEnd = () => {
      if (isPulling) {
        content.style.transition = "transform 0.3s ease"
        refreshIndicator.style.transition = "transform 0.3s ease"

        if (parseInt(content.style.transform.replace("translateY(", "")) >= 50) {
          refreshIndicator.classList.add("visible")
          content.style.transform = "translateY(50px)"
          refreshIndicator.style.transform = "translateY(50px)"

          setTimeout(() => {
            window.location.reload()
          }, 500)
        } else {
          content.style.transform = "translateY(0)"
          refreshIndicator.style.transform = "translateY(-50px)"
        }
        isPulling = false
      }
    }

    content.addEventListener("touchstart", onTouchStart, { passive: true })
    content.addEventListener("touchmove", onTouchMove, { passive: false }) // We can't use passive: true here
    content.addEventListener("touchend", onTouchEnd, { passive: true })
  }
})()
